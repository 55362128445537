<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                {{ $t("message.product") }}
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    :class="mode ? 'input__day' : 'input__night'"
                    v-model="filterForm.search"
                    :icon="'el-icon-search'"
                  ></crm-input>
                  
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right" :class="mode ? 'button__settingsday' : 'button__settingsnight'">
            <el-button size="small" class="btu-color" @click="drawerCreate = true">
                {{ $t('message.create') }}
            </el-button>
            <el-dropdown class="setting-cheek ml-2">
                <el-button
                    class="padding_none"
                    size="small"
                    icon="el-icon-open"
                ></el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                        v-for="(column, index) in columns"
                        :key="'drop' + index"
                    >
                        <el-checkbox
                            :checked="column.show"
                            @change="updateColumn({key: column.column, value: $event})"
                            >{{ column.title }}
                        </el-checkbox>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>


          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart custom__scroll">
        <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered" v-loading="loadingData">
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

              <th v-if="columns.name.show">
                {{ columns.name.title }}
              </th>

              <th v-if="columns.code.show">
                {{ columns.code.title }}
              </th>

              <th v-if="columns.is_active.show">
                {{ columns.is_active.title }}
              </th>

              <th v-if="columns.price.show">
                {{ columns.price.title }}
              </th>

              <th v-if="columns.currency_id.show">
                {{ columns.currency_id.title }}
              </th>

              <th v-if="columns.calculation_type.show">
                {{ columns.calculation_type.title }}
              </th>

              <th v-if="columns.weight.show">
                {{ columns.weight.title }} 
              </th>

              <th v-if="columns.measurement_id.show">
                {{ columns.measurement_id.title }}
              </th>

              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>

              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>

              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>

              <th v-if="columns.name.show">
                <crm-input
                  size="mini"
                  :placeholder="columns.name.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.name"
                ></crm-input>
              </th>

              <th v-if="columns.code.show">
                <crm-input
                  size="mini"
                  :placeholder="columns.code.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.code"
                ></crm-input>
              </th>

              <th v-if="columns.is_active.show">
                <crm-input
                  size="mini"
                  :placeholder="columns.is_active.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.is_active"
                ></crm-input>
              </th>

              <th v-if="columns.price.show">
                <crm-input
                  :placeholder="columns.price.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  size="mini"
                  v-model="filterForm.price"
                ></crm-input>
              </th>

              <th v-if="columns.currency_id.show">
                <select-currency
                    :size="'mini'"
                    :placeholder="columns.currency_id.title"
                    :class="mode ? 'filter__day' : 'filter__night'"
                    :id="filterForm.currency_id"
                    v-model="filterForm.currency_id"
                ></select-currency>
              </th>

              <th v-if="columns.calculation_type.show">
                <radio-calculation-type
                    v-model="filterForm.calculation_type"
                    :id="filterForm.calculation_type"
                  >
                  </radio-calculation-type>
              </th>

              <th v-if="columns.weight.show">
                <crm-input
                  :placeholder="columns.weight.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  size="mini"
                  disabled
                  v-model="filterForm.weight"
                ></crm-input>
              </th>

              <th v-if="columns.measurement_id.show">
                <select-measurement
                  :size="'mini'"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.measurement_id.title"
                  :id="filterForm.measurement_id"
                  v-model="filterForm.measurement_id"
                >
                </select-measurement>
              </th>

              <th v-if="columns.created_at.show">
                <crm-date-picker
                  size="mini"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.created_at.title"
                  v-model="filterForm.created_at"
                ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  size="mini"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.updated_at.title"
                  v-model="filterForm.updated_at"
                ></crm-date-picker>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="product in list" :key="product.id" class="cursor-pointer">
              <td v-if="columns.id.show">
                {{ product.id }}
              </td>

              <td v-if="columns.name.show">
                {{ product.name }}
              </td>

              <td v-if="columns.code.show">
                {{ product.code }}
              </td>

              <td v-if="columns.is_active.show">
                {{ product.is_active ? $t('message.active') : $t('message.inactive') }}
              </td>

              <td v-if="columns.price.show">
                {{ product.price }}
              </td>

              <td v-if="columns.currency_id.show">
                {{ product.currency ? product.currency.code : ''}}
              </td>

              <td v-if="columns.calculation_type.show">
                {{ product.calculation ? product.calculation.name : '' }}
              </td>

              <td v-if="columns.weight.show">
                {{ product.weight }}
              </td>

              <td v-if="columns.measurement_id.show">
                {{ product.measurement ? product.measurement.name : '' }}
              </td>

              <td v-if="columns.created_at.show">
                {{ product.created_at }}
              </td>

              <td v-if="columns.updated_at.show">
                {{ product.updated_at }}
              </td>

              <td v-if="columns.settings.show" class="settings-td">
                <crm-settings
                  :name="$options.name"
                  :model="product"
                  :actions="actions"
                  :permissionShow="'products.update'"
                  :permissionDestroy="'products.delete'"
                  @edit="edit"
                  @delete="destroy"
                ></crm-settings>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
          <!-- <Pagination /> -->
        </div>
      </div>

      <div class="app-modal app-modal__full modal-color-bg">
        <el-drawer
          :with-header="false"
          :visible.sync="drawerCreate"
          ref="drawerCreate"
          class="bg-se"
          size="70%"
          @opened="drawerOpened('drawerCreateChild')"
          @closed="drawerClosed('drawerCreateChild')"
        >
          <div>
            <crm-create ref="drawerCreateChild" drawer="drawerCreate">
            </crm-create>
          </div>
        </el-drawer>

        <el-drawer
          :with-header="false"
          :visible.sync="drawerUpdate"
           class="bg-se"
          size="70%"
          ref="drawerUpdate"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
        >
          <crm-update
            :selectedItem="selectedItem"
            ref="drawerUpdateChild"
            drawer="drawerUpdate"
          ></crm-update>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import selectCurrency from "@/components/selects/select-currency";
import selectMeasurement from "@/components/inventory/select-measurement";
import RadioCalculationType from "@/components/radioSelect/radio-calculation-type";

import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "products",
  mixins: [list],
  components: {
    CrmCreate,
    CrmUpdate,
    selectCurrency,
    selectMeasurement,
    RadioCalculationType
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      list: "products/list",
      columns: "products/columns",
      pagination: "products/pagination",
      filter: "products/filter",
      sort: "products/sort",
      mode: "MODE"
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "products/index",
      setPagination: "products/setPagination",
      updateSort: "products/updateSort",
      updateFilter: "products/updateFilter",
      updateColumn: "products/updateColumn",
      updatePagination: "products/updatePagination",
      show: "products/show",
      empty: "products/empty",
      delete: "products/destroy",
      refreshData: "products/refreshData",
      getProducts: "products/getProducts",
    }),
    destroy(model) {          
      this.delete(model.id)
          .then(res => {
            if(res.data.result.success){
              this.$alert(res);
              this.fetchData()
            }else{
              this.$warning_message(res);
            }

          })
          .catch(err => {
            this.$alert(err);
          })
    },
    importProducts(){
      this.$confirm(
        $t('message.Do you really want to import products from another project'),
        this.$t('message.warning'), {
          confirmButtonText: "Да",
          cancelButtonText: "Отмен",
          type: "warning"
        }
      )
      .then(() => {
        this.loadingData = true,
        this.getProducts()
          .then(response => {
            const query = {...this.filter, ...this.pagination, ...this.sort,};
            this.updateList(query)
            .then(res => {
              this.loadingData = false;
              this.$alert(response);
            });
          })
      })
      .catch(() => {
        this.loadingData = false
        this.$message({
          type: "warning",
          message: this.$t('message.operation_canceled')
        });
      });
    }
  },
  beforeRouteLeave (to, from, next) {
      this.$store.commit('products/EMPTY_LIST');
      next()
  },
};
</script>
<style scoped>
.el-dropdown-menu__item > label {
    margin-bottom: 0.1rem !important;
}

.el-dropdown-menu {
    max-height: 720px !important;
    overflow-y: scroll;
}
.el-dropdown-menu__item,
.el-checkbox__label,
.el-checkbox {
    font-size: 12px !important;
}
</style>
